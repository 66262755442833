import request from '@/utils/request'

// 快递公司选项数据
export function findExpressCompanyOptions() {
  return request({
    url: `/agent_purchase_order_deliveries/express_company_options`,
    method: 'get'
  })
}

// 创建订单发货数据
export function createAgentPurchaseOrderDelivery(data) {
  return request({
    url: `/agent_purchase_order_deliveries`,
    method: 'post',
    data
  })
}

// 查询单个发货信息表单值
export function findAgentPurchaseOrderDeliveryForm(id) {
  return request({
    url: `/agent_purchase_order_deliveries/${id}/form`,
    method: 'get'
  })
}

// 更新客户套餐
export function updateAgentPurchaseOrderDelivery(id, data) {
  return request({
    url: `/agent_purchase_order_deliveries/${id}`,
    method: 'put',
    data
  })
}
