<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="订单发货"
      @ok="handleSubmit"
    >
      <a-row>
        <a-col :span="8">
          <a-descriptions title="基本信息" :column="1">
            <a-descriptions-item label="客户名称">
              {{ data.agent_name }}
            </a-descriptions-item>

            <a-descriptions-item label="订单编号">
              {{ data.order_no }}
            </a-descriptions-item>

            <a-descriptions-item label="项目数量">
              {{ data.items_count }}
            </a-descriptions-item>

            <a-descriptions-item label="订单状态">
              {{ data.status }}
            </a-descriptions-item>

            <a-descriptions-item label="创建时间">
              {{ data.created_at }}
            </a-descriptions-item>

            <a-descriptions-item label="订单备注">
              {{ data.remark }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="8">
          <a-descriptions title="订单金额" :column="1">
            <a-descriptions-item label="总卡数(张)">
              {{ data.card_count }}
            </a-descriptions-item>

            <a-descriptions-item label="订单金额(元)">
              {{ data.order_fee | formatFloat }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="8">
          <a-descriptions title="收货信息" :column="1">
            <a-descriptions-item label="收货方式">
              {{ data.pickup_way }}
            </a-descriptions-item>

            <a-descriptions-item label="收货人" v-if="data.pickup_way_slug === 'post'">
              {{ data.receiver }}
            </a-descriptions-item>

            <a-descriptions-item label="联系方式" v-if="data.pickup_way_slug === 'post'">
              {{ data.phone_number }}
            </a-descriptions-item>

            <a-descriptions-item label="所属地区" v-if="data.pickup_way_slug === 'post'">
              {{ data.region }}
            </a-descriptions-item>

            <a-descriptions-item label="地区邮编" v-if="data.pickup_way_slug === 'post'">
              {{ data.post_code }}
            </a-descriptions-item>

            <a-descriptions-item label="详细地址" v-if="data.pickup_way_slug === 'post'">
              {{ data.address_detail }}
            </a-descriptions-item>

          </a-descriptions>
        </a-col>
      </a-row>

      <a-row>
        <a-divider />
        <a-descriptions title="发货信息" :column="1" />

        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 9 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row :gutter="24" v-if="data.pickup_way_slug === 'post'">
            <a-col :span="8">
              <a-form-item label="快递公司">
                <a-select
                  :loading="loadingExpressCompanyOptions"
                  @focus="fetchExpressCompanyOptions"
                  v-decorator="['express_company', {
                    rules: [{ required: true, message: '请选择快递公司' }]
                  }]"
                  allow-clear
                >
                  <a-select-option
                    v-for="company in expressCompanyOptions"
                    :key="company.value"
                    :value="company.value"
                  >
                    {{ company.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item label="快递单号">
                <a-input
                  v-decorator="['express_no', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入快递单号' }
                    ]
                  }]"
                  allow-clear
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item label="发货时间">
                <a-date-picker
                  style="width: 100%;"
                  v-decorator="['delivery_time', {
                    rules: [
                      { required: true, message: '请选择发货时间' }
                    ]
                  }]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="24" v-if="data.pickup_way_slug === 'self_take'">
            <a-col :span="8">
              <a-form-item label="提货人">
                <a-input
                  v-decorator="['self_take_name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入提货人' }
                    ]
                  }]"
                  allow-clear
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item label="联系方式">
                <a-input
                  v-decorator="['contact_way', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入联系方式' }
                    ]
                  }]"
                  allow-clear
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item label="提货时间">
                <a-date-picker
                  style="width: 100%;"
                  v-decorator="['delivery_time', {
                    rules: [
                      { required: true, message: '请选择提货时间' }
                    ]
                  }]"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { findAgentPurchaseOrder } from '@/api/agent_purchase_order'
import { findExpressCompanyOptions, createAgentPurchaseOrderDelivery } from '@/api/agent_purchase_order_delivery'
import { formatDate } from '@/utils/time'

export default {
  name: 'DeliverAgentPurchaseOrder',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loadingExpressCompanyOptions: false,
      expressCompanyOptions: [],
      submitting: false,
      form: this.$form.createForm(this, { name: 'agent_purchase_order_delivery' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchExpressCompanyOptions() {
      this.loadingExpressCompanyOptions = true
      findExpressCompanyOptions().then((res) => {
        this.expressCompanyOptions = res.data
        this.loadingExpressCompanyOptions = false
      })
    },

    fetchData() {
      findAgentPurchaseOrder(this.id).then((res) => {
        this.data = res.data
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = Object.assign({ agent_purchase_order_id: this.id }, {
            ...values,
            delivery_time: formatDate(values.delivery_time)
          })

          createAgentPurchaseOrderDelivery(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
